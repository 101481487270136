<template>
    <swiper
        :direction="'horizontal'"
        :slidesPerView="auto"
        :centeredSlides="true"
        :navigation="true"
        :pagination="true"
        :effect="'coverflow'"
        :coverflowEffect="{
            rotate: 90,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        }"
        :mousewheel="true"
        :keyboard="{
            enabled: true,
        }"
        :modules="modules"
    class="mySwiper text-center">
        <swiper-slide v-for="(image, index) in imageList" :key="index"><img :src="image.src" :alt="image.alt" :class="image.class"/></swiper-slide>
    </swiper>
</template>

<script>
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Keyboard, Navigation, Pagination, Mousewheel, EffectCoverflow} from 'swiper/modules';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';
    import 'swiper/css/effect-coverflow';
    export default {
        name: 'SwiperBase',
        data: function() {
            return {
            }
        },
        methods: {
        },
        components: {
            Swiper,
            SwiperSlide,
        },
        props: ['imageList'],
        setup() {
            return {
                modules: [Keyboard, Navigation, Pagination, EffectCoverflow, Mousewheel],
            };
        },
    }
</script>

<style scoped>
    .mySwiper img {
        max-width: 20rem;
        max-height: 20rem;
    }

    @media (max-width: 768px) {
        .mySwiper img {
            margin-top: 1rem;
        }
    }
</style>
