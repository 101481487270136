import { createApp } from 'vue'
//import Vue from 'vue'
import App from './App.vue'
const vue = createApp(App);

//import { BootstrapVue } from 'bootstrap-vue';
import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap-vue/dist/css/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
//Vue.use(BootstrapVue)

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

/* import specific icons */
//import { all } from '@awesome.me/kit-free-solid/icons'

/* add icons to the library */
//library.add(...all)
library.add(fas, far)

import { GlobalEvents } from 'vue-global-events'


vue.component('font-awesome-icon', FontAwesomeIcon)
vue.component('GlobalEvents', GlobalEvents)

vue.mount('#app')
//createApp(PrivacyPolicy).mount('#policy')
