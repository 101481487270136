<template>
  <div class="container">
    <div class="row heading">
      <div class="col">
        <h1>Wesley Darnell</h1>
      </div>
      <div class="col-sm">
        <span>//</span>
      </div>
      <div class="col">
        <h1>WWDEV</h1>
      </div>
    </div>
    <div class="row links">
      <div class="col">
          <a href="https://www.linkedin.com/in/wesleydarnell" target="_blank" rel="noopener">
            <img src="https://img.shields.io/badge/LinkedIn-0077B5?style=for-the-badge&logo=linkedin&logoColor=white&logoHeight=8" alt="LinkedIn">
          </a>
        <!--Github-->
          <a href="https://github.com/wesleydarnell" target="_blank" rel="noopener">
            <img src="https://img.shields.io/badge/GitHub-100000?style=for-the-badge&logo=github&logoColor=white&logoHeight=8" alt="GitHub">
          </a>
      </div>
      <div class="col-sm subtitle">
        <h2>Software Developer</h2>
      </div>
      <div class="col">
        <!-- Playstore-->
          <a href="https://play.google.com/store/apps/developer?id=WWDEV" target="_blank" rel="noopener">
            <img src="https://img.shields.io/badge/PlayStore-414141?style=for-the-badge&logo=google-play&logoColor=white&logoHeight=8" alt="PlayStore">
          </a>
          <!-- Thingiverse -->
          <a href="https://www.thingiverse.com/wesleydarnell/designs" target="_blank" rel="noopener">
            <img src="https://img.shields.io/badge/Thingiverse-00ABE5?style=for-the-badge&logo=thingiverse&logoColor=white&logoHeight=8" alt="Thingiverse">
          </a>
      </div>
    </div>
    <div class="row mt-5 justify-content-center">
      <div class="col-md-8">
        <p class="bio">
          I am a developer who is highly motivated and passionate for all things tech.
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 projects">
        <h2>Projects</h2>
        <ul class="px-0">
          <RetroDigitalWatchFace />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import RetroDigitalWatchFace from './projects/RetroDigitalWatchFace.vue'
  export default {
    name: 'WesleyDarnell',
    components: {
      RetroDigitalWatchFace
    },
    data: function() {
      return {
      }
    },
    methods: {
    }
  }

</script>

<style>

.col {
  place-self: center;
}

.col:first-child {
  text-align: right;
}

.col:last-child {
  text-align: left;
}

.heading .col:first-child, .heading .col:last-child, .projects h2{
  border-bottom: 2px solid var(--highlight);
}

.heading h1 {
  font-size: 3em;
  text-wrap: nowrap;
}

.heading span {
  font-size: 2.5em;
  color: var(--highlight);
}

.heading h2 {
  text-align: left;
}

.subtitle {
  align-self: center;
}

.subtitle h2 {
  margin: 0;
}

.bio {
  border-bottom: 3px solid #8080805e;
  text-shadow: 0px 3px 16px black;
}

.projects h2 {
  color: var(--primary-color);
}

.projects ul {
  text-align: left;
  color: var(--primary-color);
}

ul {
  list-style-type: none;
}

ul p {
  color: var(--secondary-color);
}

ul span {
  color: var(--highlight);
}

@media (max-width: 576px) {
  .subtitle {
    order: -1!important;
  } 

  .heading .col:first-child {
    border-bottom: none;
  }

  .links .col:first-child {
    flex: 100%;
  }

  .heading h1 {
    text-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .links .col, .heading .col {
    text-align: center !important;
  }

  .projects .row .col:first-child {
    flex: 100%
  }
}
@media (max-width: 990px) {
  .projects .col {
    text-align: center !important;
  }
}
</style>