<template>
    <div class="row">
        <div class="col">
            <li>Retro Digital Dash Watch Face</li>
            <p class="mb-0">In Progress - <span>Closed Source</span></p>
            <a href="https://play.google.com/store/apps/details?id=com.wwdev.DigitalDash" target="_blank">Download from the playstore here!</a><br>
            <a class="pb-3" href="/privacypolicy">Privacy Policy</a>
        </div>
        <!-- <div class="col watch-pic">
            <img src="@/assets/images/retro_digital_dash.png"  />
        </div> -->
        <div class="col-lg-6">
            <SwiperBase :imageList="images"/>
        </div>
    </div>
</template>

<script>
import SwiperBase from './SwiperBase.vue'

export default {
    name: 'RetroDigitalWatchFace',
    data: function() {
        return {
            images: [{
                src: new URL("@/assets/images/wf_img.png", import.meta.url),
                alt: "Watch Face Image",
            },
            {
                src: new URL("@/assets/images/retro_digital_dash.png", import.meta.url),
                alt: "Retro Digital Dash"      ,
                class: "watch-pic"              
            }]
        }
    },
    methods: {
    },
    components: {
      SwiperBase
    },
    setup() {
      return {
      };
    },
}
</script>

<style>
    .watch-pic {
        border-radius: 10rem;
        border: 0.3vw solid rgb(46, 46, 46);
        box-shadow: 0 0 3px 9px rgb(65, 65, 65) inset, 0 0 3px 2px rgb(48, 48, 48);
    }
</style>

<style scoped>
    .mySwiper img {
        max-width: 20rem;
        max-height: 20rem;
    }

    @media (max-width: 768px) {
        .mySwiper img {
            margin-top: 1rem;
        }
    }
</style>